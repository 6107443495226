<template>
  <div class="panel">
    <div class="panel-heading">Filtros</div>
    <form class="panel-block" @keyup.enter="filtrarEmpresas">
      <b-field expanded grouped class="column-direction-touch is-flex-grow-1">
        <b-field expanded label="Status">
          <b-select expanded placeholder="Status" v-model="filters.status">
            <option v-for="status in statusTypes" :key="status.id" :value="status.id">{{
              status.nome
            }}</option>
          </b-select>
        </b-field>

        <b-field expanded class="break-attachment-field" label="Período">
          <b-field expanded>
            <b-datepicker
              editable
              expanded
              placeholder="Início"
              icon="calendar-today"
              v-mascara:data
              :max-date="maiorDataInicio"
              v-model="filters.period.start"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            >
              <button
                class="button is-primary br-4 mr-1"
                @click.prevent.stop="insertTodayFilter('start')"
              >
                <b-icon icon="calendar-today" />
                <span>Hoje</span>
              </button>

              <button class="button is-danger br-4" @click.prevent.stop="cleanDateFilter('start')">
                <b-icon icon="close" />
                <span>Limpar</span>
              </button>
            </b-datepicker>

            <b-datepicker
              editable
              expanded
              placeholder="Fim"
              icon="calendar-today"
              v-mascara:data
              :disabled="desabilitarPeriodo"
              :min-date="dataMinimaFim"
              v-model="filters.period.end"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            >
              <button
                class="button is-primary br-4 mr-1"
                @click.prevent.stop="insertTodayFilter('end')"
              >
                <b-icon icon="calendar-today" />
                <span>Hoje</span>
              </button>

              <button class="button is-danger br-4" @click.prevent.stop="cleanDateFilter('end')">
                <b-icon icon="close" />
                <span>Limpar</span>
              </button>
            </b-datepicker>
          </b-field>
        </b-field>

        <div grouped class="column-direction-touch filters-buttons-container">
          <button class="button is-primary filtrar" @click.stop.prevent="validarFiltros">
            Filtrar
          </button>

          <button class="button is-primary filtrar" @click.stop.prevent="limparFiltros">
            Limpar
          </button>
        </div>
      </b-field>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import campo from '@/mixins/formulario';

export default {
  name: 'EmpresasPendentesListaFiltro',
  mixins: [campo],
  data() {
    const statusTypes = [
      { id: 'todas', nome: 'Todas' },
      { id: 'pendentes', nome: 'Pendentes' },
      { id: 'invalidas', nome: 'Inválidas' },
      { id: 'bloqueadas', nome: 'Bloqueadas' },
      { id: 'validas', nome: 'Válidas' },
    ];

    const filters = {
      status: 'pendentes',
      period: {
        start: null,
        end: null,
      },
      page: 1,
    };

    return {
      filters,
      statusTypes,
    };
  },
  computed: {
    dataMinimaFim() {
      const start = new Date(this.filters.period.start);
      if (start) {
        start.setDate(start.getDate() + 1);
        return start;
      }
      return null;
    },
    desabilitarPeriodo() {
      return this.filters.period.start === null;
    },
    maiorDataInicio() {
      if (this.filters.period.end) {
        const data = new Date(this.filters.period.end);
        data.setDate(data.getDate() - 1);
        return data;
      }
      return null;
    },
  },
  methods: {
    ...mapActions(['filtrarEmpresasPendentes']),
    limparPeriodo() {
      this.filters.period.start = null;
      this.filters.period.end = null;
    },
    validarFiltros() {
      if (
        this.filters.period.start &&
        this.filters.period.end &&
        this.filters.period.start > this.maiorDataInicio
      ) {
        this.limparPeriodo();
        return this.$alerta('O período deve ter no mínimo um dia', 'is-danger');
      }

      if (
        this.filters.period.start &&
        this.filters.period.end &&
        this.filters.period.end < this.dataMinimaFim
      ) {
        this.limparPeriodo();
        return this.$alerta('O período deve ter no mínimo um dia', 'is-danger');
      }

      return this.filtrarEmpresas(this.filters);
    },
    filtrarEmpresas() {
      this.filtrarEmpresasPendentes(this.filters);
    },
    limparFiltros() {
      this.filters.status = 'pendentes';
      this.filters.period.start = null;
      this.filters.period.end = null;
      this.filtrarEmpresas();
    },
  },
  created() {
    this.filtrarEmpresas();
  },
};
</script>

<style lang="scss" scoped>
.filtro {
  height: 100%;
}

.field.is-grouped {
  width: 100%;
  justify-content: space-between;
}

.filters-buttons-container {
  display: flex;

  button {
    height: 75px;
    margin: 5px;
  }
}

@media (max-width: 1023px) {
  .filters-buttons-container button {
    height: unset;
    margin: 10px 0;
  }
}
</style>
